import React from 'react'
import PropTypes from 'prop-types'
import Content from './Content'
import {Link} from 'gatsby'
import NewsCard from './NewsCard'

const UpdatesArchivePageTemplate = ({ title, notice, content, contentComponent, posts }) => {
  const PageContent = contentComponent || Content

  function Notice({notice}) {
    if(notice.title !== null) {
      return (
        <article className="message">
          <div className="message-header">
            {notice.title}
          </div>
          <div className="message-body">
            <p>{notice.message}</p>
          </div>
        </article>
      )
    }
    else {
      return (
        < ></ >
      )
    }
  }

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-1">
            <div className="section">
              <h1 className="title is-size-1 has-text-weight-bold is-bold-light">
                {title}
              </h1>
              <PageContent className="content" content={content} />
            </div>
            <div className="news-preview-container">
              {posts.map(({ node: post }) =>(
                <NewsCard key={post.id} post={post} />
              ))}
              </div>
            </div>
        </div>
      </div>
    </section>
  )
}

UpdatesArchivePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default UpdatesArchivePageTemplate
